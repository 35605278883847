import React from "react"

import Layout from "../components/Layout"
import Heading from "../components/Layout/Heading"

import SEO from "../components/SEO"

const TermsOfUse = (props) => (
  <Layout location={props.location}>
    <SEO title="Terms of Use" />
    <Heading title="Terms of Use" />
    <div className="row">
      <div className="col-12">
        <p>
          This website is owned by Stuarts Coaches and operated by DSS Digital.
          By accessing, browsing or using this website, you agree to the terms,
          conditions and disclaimers herein as amended from time to time.
        </p>
        <p className="sub-heading">Trademarks, Copyrights and Restrictions</p>
        <p>
          All material on this site, including, but not limited to images, logos
          and illustrations, is protected by copyrights, trademarks, and other
          intellectual property rights which are owned and controlled by Stuarts
          Coaches and its related companies. The content of
          www.stuartscoaches.com.au is provided for your personal,
          non-commercial use. Such material may not be copied, republished,
          reproduced, uploaded, modified, transmitted, posted, or distributed in
          any way without the consent of Stuarts Coaches. The use of this
          website and these conditions is in accordance with the laws of
          Australia. Any legal action arising out of its use shall be brought
          and enforced under Australian laws. By using this site, you agree to
          submit to the jurisdiction of the courts of Australia and any legal
          action pursued by you shall be within the exclusive jurisdiction of
          the courts of Australia
        </p>
        <p className="sub-heading">Links to other sites</p>
        <p>
          Stuarts Coaches is not responsible for the privacy practices or the
          content of other sites that are linked to www.stuartscoaches.com.au
        </p>
      </div>
    </div>
  </Layout>
)

export default TermsOfUse
